.jobs {
    margin: 4rem auto 0 auto;
    max-width: 1100px;
}

.job:last-child {
    border-bottom: 1px solid silver;
}

.job {
    border-top: 1px solid silver;
    padding: 2rem 2rem;
    display: flex;
    flex-direction: column;
    gap: 2rem;
    transition: all .25s ease;
}

.job:hover {
    background-color: rgb(246, 246, 246);
}

.job--tags {
    display: flex;
    gap: 1rem;
    margin-top: .5rem;
}

.job--tag {
    border: 1px solid silver;
    padding: .25rem .5rem;
    border-radius: 1rem;
}

.job--description {
    font-size: 1rem !important;
}

.job a {
    font-size: 1.2rem;
    padding: .25rem .5rem;
    border-radius: .25rem;
    border: 1px solid var(--main);
    color: var(--main);
    transition: all .25s ease;
    text-decoration: none;
}

.job a:hover {
    background-color: var(--main);
    color: white;
}

@media (min-width: 768px) {
    .job {
        flex-direction: row;
    }
}

