.navbar {
    position: fixed;
    top: 0;
    padding: .5rem 0;
    color: var(--light);
    width: 100%;
    background: linear-gradient(180deg, rgba(66, 66, 66, 0.36) 0%, rgba(60, 60, 60, 0) 100%);
    backdrop-filter: blur(2px);
    z-index: 100;
}

.navbar--content {
    display: flex;
    max-width: 1200px;
    margin: auto;
    padding: 0 .5rem;
}

.logo {
    width: 8rem;
}

.menu--wrapper {
    margin-left: auto;
}

.navbar--menu {
    list-style: none;
    display: none;
    gap: 1rem;
    text-transform: capitalize;
    font-size: 1rem;
    font-weight: 600;
    padding: .5rem;
    
}

.navbar--menu a {
    text-decoration: none;
    color: var(--light);
    transition: all .25s ease;
    font-size: 1.2rem;
}



.menu-btn--wrapper {
    height: 48px;
    width: 48px;
    margin-right: 1rem;
    transition: all .25s ease;
}

.menu-btn--wrapper:hover {
    scale: 1.1;

}

.menu-btn {
    background-color: transparent;
}

.menu-btn svg {
    width: 100%;
}

.dropdown--wrapper {
    position: fixed;
    inset: 0;
    z-index: 100;
    transition: all .25s ease;
    backdrop-filter: blur(2px) brightness(.5);
}

.navbar--dropdown {
    position: absolute;
    top: 5rem;
    left: 1rem;
    right: 1rem;
    background-color: #555;
    padding: 1rem 2rem;
    border-radius: 1rem;
    place-content: center;
    text-align: center;
    overflow: hidden;
    box-shadow: rgba(255, 255, 255, 0.1) 0px 1px 1px 0px inset, rgba(50, 50, 93, 0.25) 0px 50px 100px -20px, rgba(0, 0, 0, 0.3) 0px 30px 60px -30px;
}


.navbar--dropdown menu {
    margin: auto;
    list-style: none;
    display: flex;
    flex-direction: column;
    gap: 1rem;
}

.navbar--dropdown a {
    text-decoration: none;
    color: var(--light);
    font-size: 1.2rem;
}

.navbar--dropdown li, .navbar--menu li {
    transition: all .25s ease;
}

.navbar--dropdown li:hover, .navbar--menu li:hover {
    translate: 0 -.1rem;
}

.hidden-top {
    translate: 0 -300% ;
}


@media (min-width: 768px) {
    .logo {
        width: 10rem;
    }
    .navbar--menu {
        display: flex;
    }
    .menu-btn {
        display: none;
    }

    .navbar--dropdown {
        display: none;
    }
    
}

@media (min-width: 1024px) {
    .logo {
        width: 12rem;
    }
}

@media (min-width: 1028) {
    .logo {
        width: 14rem;
    }
}