.request-service input, .request-service select, .request-service option {
    font-size: 1.2rem;
    font-family: inherit;
}

.request-form h4 {
    margin-top: 1.5rem;
    margin-bottom: .5rem;
}

.request-form h5 {
    margin-top: 1rem;
    margin-bottom: .5rem;
}

.choose-services ul {
    display: flex;
    flex-wrap: wrap;
}

.choose-services li {
    list-style: none;
    margin: 1.25rem .25rem;
}


.choose-services label {
    border: 1px solid silver;
    padding: 1rem .5rem;
    border-radius: .5rem;
    position: relative;
    cursor: pointer;
    transition: all .25s ease;
}


.choose-services label:hover {
    background-color: rgb(241, 240, 240);
}

.choose-services input {
    position: absolute;
    top: .1rem;
    left: .1rem;
}

.choose-services input:checked {
    font-weight: bold;
}

.address--container {
    display: flex;
    flex-direction: column;
    max-width: 700px;
    gap: .5rem;
    margin-top: .5rem;
}

.address--container label {
    display: flex;
    flex-direction: column;
}
.address--container input, .address--container select {
    border: 1px solid silver;
    font-size: 1.2rem;
    padding: .1rem;
    border-radius: .25rem;
    margin: 0 .25rem;
}

.city--container {
    display: flex;
    flex-wrap: wrap;
}

.city--container input, .city--container select {
    width: 220px;
} 

.time--container select, .time--container input {
    font-size: 1.2rem;
    margin: 0 .2rem;
}

.phone--input {
    font-size: 1.2rem;
    padding: .1rem;
    margin-top: .5rem;
    border-radius: .25rem;
}



.request-form button {
    margin-top: .5rem;
}

