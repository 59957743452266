.lead-form-bottom {
    background-color: #222;
    color: var(--light);
    padding: 6rem 0 2rem 0;
}

.lead-form-bottom--container {
    max-width: 900px;
    margin: auto;
}

.bottom-form {
    max-width: 600px;
    margin: auto;
    padding: 0 1rem;
    display: flex;
    flex-direction: column;
    gap: 1rem;
}

.bottom-form h3 {
    font-size: 2.5rem;
}

.lead-form-bottom button {
    background-color: var(--main);
    border: none;
    border-radius: .25rem;
    padding: 1rem 2rem;
    font-size: 1.2rem;
    color: var(--light);
}

.lead-form-bottom input {
    border-radius: .25rem;
    padding: 1rem;
    font-size: 1.2rem;
    color: var(--light);
    color: #333;
    background-color: silver;
}

.lead-form-bottom input::placeholder {
    color: #555;
}