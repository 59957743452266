.not-found {
    margin-top: 6rem;
}


.not-found--img {
    width: 100%;
    max-width: 600px;
}

.not-found h2 {
    margin: 1rem 0;
}