.client-support form {
    margin-top: 3rem;
    display: flex;
    gap: .5rem;
    flex-direction: column;
    max-width: 600px;
}

.ticket-form select {
    border-radius: .25rem;
    padding: .25rem;
    font-size: 1.2rem;
}

.client-support textarea {
    font-size: 1.2rem;
    border-radius: .25rem;
    padding: .5rem;
    min-height: 200px;
}