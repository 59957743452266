.header-ad {
    display: grid;
    place-content: center;
    padding: 2rem 1rem 0 1rem;
    height: 200px;
}

.header-ad img {
    height: 100%;
    width: 100%;
    object-fit: scale-down;
    max-height: 160px;

}