@import url('https://fonts.googleapis.com/css2?family=DM+Sans:ital,opsz,wght@0,9..40,100..1000;1,9..40,100..1000&display=swap');
@import './styles/page.css';

* {
  margin: 0;
  padding: 0;
}

:root {
  --main: rgb(240, 44, 43);
  --light: rgb(237, 240, 242);
  --bg: #eff0ec;
}

html {
  scroll-behavior: smooth;

}

body {
  font-family: "DM Sans", sans-serif;
}

strong {
  color: var(--main);
}

a:hover {
  cursor: pointer;
}


button {
  border: none;
}

button:hover {
  cursor: pointer;
}

.line-break {
  background-color: var(--main);
  width: 50%;
  height: 2px;
  margin: 4rem auto;
}

.btn--main {
  color: var(--light);
  background-color: var(--main);
  padding: .5rem 1rem;
  font-size: 1.2rem;
  border-radius: .25rem;
  transition: all .25s ease;
  text-decoration: none;
}

.btn--main:hover {
  color: var(--main);
  background-color: white;
  border: 1px solid var(--main);
}


.dashboard--container {
  padding: 120px 1rem 1rem 1rem;
  
}

.dashboard--container h2 {
  font-size: 2rem;
}

.dashboard--container h3 {
  font-size: 1.5rem;
  margin-top: .5rem;
  margin-bottom: .25rem;

}

.dashboard--container h4 {
  font-size: 1.2rem;
}

.dashboard--container h5 {
  font-size: 1rem;
  margin-top: .5rem;
}

@media (min-width: 1024px) {

  .dashboard--container {
    padding: 2rem 1rem 2rem 300px;
  }
}

@media (min-width: 1280px) {
  .dashboard--container {
    padding: 2rem 1rem 2rem 400px;
  }
}

.sub-heading {
  font-size: 1.4rem;
}

.success {
  color: blue;
}

.active-navbar {
  position: relative;
  cursor: default !important;
}

.active-navbar::after {
  content: ' ';
  background-color: silver;
  width: 100%;
  height: 2px;
  position: absolute;
  bottom: -.25rem;
  left: 0;
  border-radius: 2px;
}

.flex-wrap {
  display: flex;
  flex-wrap: wrap;
  gap: .5rem;
}

.tawk-branding a {
  display: none !important;
}