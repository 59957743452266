.footer-contact {
    background-color: #222;
}


.bottom--contacts {
    display: grid;
    gap:1.5rem;
    text-align: center;
    padding-top: 4rem;
    max-width: 1200px;
    margin: auto;
}

.bottom--contact h4 {
    font-size: 2.5rem;
    color: #d4ddb7;
}

.bottom--contact a {
    font-size: 1.25rem;
    color: var(--light);
    text-decoration: none;
}


@media (min-width: 768px) {
    .bottom-form h3 {
        font-size: 3.5rem;
    }
    .bottom-form p {
        font-size: 1.25rem;
    }
    .bottom--contacts {
        grid-template-columns: 1fr 1fr 1fr;
    }


}