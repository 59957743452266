.billing button {
    padding: .25rem;
    border-radius: .25rem;
    margin: .25rem;
}

.card-form {
    max-width: 700px;
}



.card-form svg {
    width: 32px;
    height: 32px;
}

.card-form--address label, .card-form--number label {
    display: flex;
    flex-direction: column;
}

.card-form input, .card-form select {
    padding: .25rem;
    font-size: 1.2rem;
    border-radius: .25rem;
}

.billing--city {
    display: flex;
    flex-wrap: wrap;
}

.billing--city input, .billing--city select {
    width: 200px;
}

.card-form--bottom {
    margin-top: 1rem;
    display: flex;
    gap: 1rem;
    align-items: center;
}

.btn--cancel {
    font-weight: 700;
    cursor: pointer;
}

.agree-box {
    cursor: pointer;
}

.agree-link {
    font-weight: 700;
    margin-left: .25rem;
}