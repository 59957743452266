.footer-ad {
    display: grid;
    place-content: center;
    padding: 1rem 1rem 12rem 1rem;
    max-width: 1200px;
    margin: auto;
}

.footer-ad img {
    width: 100%;
    max-height: 160px;
    object-fit: scale-down;
}
    
 