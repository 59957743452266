.home {
    background-color: #eff0ec;
}

.home h3 {
    font-size: 2.5rem;
}

.home input {
    background-color: #999;
}

.home input::placeholder {
    color: rgb(195, 193, 193);
}

@media (min-width: 768px) {
    .home h3 {
        font-size: 3rem;
    }
}

@media (min-width: 1200px) {
    .home h3 {
        font-size: 4rem;
    }
}