.client-footer {
    display: grid;
    place-content: center;
    text-align: center;
    margin-top: 6rem;
}

.client-footer menu {
    display: flex;
    gap: 2rem;
}

.client-footer li {
    list-style: none;
}

.client-footer--contact {
    margin-top: 2rem;
}