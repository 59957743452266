.client-sidebar {
    position: fixed;
    left: 0;
    top: 0;
    height: 80px;
    width: 100%;
    background-color: #222;
    padding: .25rem 1rem;
    flex-direction: row;
    gap: 1rem;
    align-items: center;
    z-index: 100;
}

.client-sidebar menu {
    margin-top: 3rem;
    border-top: 1px solid #444;
    padding-top: 3rem;
    list-style: none;
    gap: 1rem;
    display: none;
}

.client-sidebar menu img {
    width: 32px;
    height: 32px;
}

.sidebar--menu-btn {
    width: 32px;
    height: 32px;
    background-color: transparent;
}

.sidebar--menu-btn svg {
    width: 100%;
}

.logo--container {
    width: 120px;
}

.logo--container img {
    width: 100%;
}

.sidebar--dropdown--wrapper {
    position: fixed;
    top: 88px;
    left: 0;
    bottom: 0;
    width: 100%;
    backdrop-filter: blur(5px);
    transition: all .25s ease;
    z-index: 100;
}

.sidebar--dropdown--wrapper menu {
    background-color: #333;
    padding: 2rem 2rem .5rem 2rem;
    max-width: 220px;
    height: 100%;
    list-style: none;
    gap: 1rem;
    display: flex;
    flex-direction: column;
}

.sidebar--dropdown--wrapper img {
    width: 32px;
    height: 32px;
}

.side-bar--logo--container {
    width: 120px;
}

.side-bar--logo--container img {
    width: 100%;
}

.sidebar--menu {
    color: var(--light);
    display: grid;
    padding-top: 4rem;
    gap: 1rem;
}

.sidebar--menu li {
    text-decoration: none;
    font-weight: 700;
}

.sidebar--menu img {
    width: 32px;
    height: 32px;
}

.menu--item a {
    gap: 1rem;
    color: var(--light);
    text-decoration: none;
    display: flex;
    align-items: center;
    border-radius: .5rem;
    padding: .5rem .5rem;
    transition: all .25s ease;
}


.sign-out {
    text-align: center;
    width: 100%;
    color: red;
    margin-top: 2rem;
    padding-top: 1rem;
    border-top: 1px solid #444;
}

.hide-left {
    outline: auto;
    transform: translate(-110%, 0);
}

.active-sidebar {
    background-color: #444;
}

@media (min-width: 1024px) {
   .client-sidebar {
        height: 100vh;
        width: 200px;
        border-top-right-radius: 1rem;
   }

   .logo--container {
    width: 180px;
    margin-top: 1.5rem;
   }

   .client-sidebar menu {
    display: grid;
   }

   .sidebar--menu-btn {
    display: none;
   }

   .sidebar--dropdown--wrapper {
    display: none;
   }
}

@media (min-width: 1280px) {
    .client-sidebar {
        width: 300px;
    }
    .logo--container {
        width: 200px;
       }

    .client-sidebar menu {
        font-size: 1.2rem;
       }
}