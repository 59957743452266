.hero {
    min-height: 70vh;
    background: url('https://cdn.shopify.com/s/files/1/0738/2987/6008/files/Untitled_design_35.png?v=1718212244');
    background-position: 75% 50%;
    background-size: cover;
    color: var(--light);
    display: grid;
    place-content: center;
    text-align: center;
}

.hero--message {
    padding: .5rem;
}

.hero h2 {
    font-size: 2.75rem;
    text-transform: capitalize;
    margin: 8rem auto 0 auto;
}

.hero span {
    color: var(--main);
}

.hero p {
    font-size: 1.5rem;
}

.hero--message {

}

.hero--tags {
    margin: .5rem .5rem 0 .5rem;
    list-style: none;
    justify-content: space-between;
    border-top: 1px solid var(--light);
    border-bottom: 1px solid var(--light);
    padding: .5rem 0;
}

.hero--tags li {
    margin-top: .25rem;
    margin-bottom: .25rem;
    font-size: 1.75rem;
    font-weight: 500;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: .5rem;
}

.hero--tags img {
    width: 48px;
    height: 48px;
    color: var(--light);
}

.hero--form {
    margin: 3rem 0 2.5rem 0;
}

.hero--form input {
    padding: 1rem;
    border-radius: .5rem;
    font-size: 1.25rem;
    color: #333;
    background-color: silver;
}

.hero--form input::placeholder {
    color: #555;
}

.hero--form button {
    background-color: var(--main);
    color: var(--light);
    border: none;
    border-radius: .5rem;
    padding: 1rem;
    margin-left: .25rem;
    font-size: 1.25rem;
}

@media (min-width: 640px) {
    .hero--tags {
        display: flex;
    }

    .hero--tags img {
        width: 32px;
        height: 32px;
    }

    .hero h2 {
        font-size: 4rem;
    }

}

@media (min-width: 768px) {

    .hero--tags img {
        width: 64px;
        height: 64px;
    }

    .hero h2 {
        font-size: 5rem;
        max-width: 13ch;
        line-height: 4rem;

    }

    .hero p {
        font-size: 2rem;
    }
}

@media (min-width: 1280px) {
    .hero h2 {
        font-size: 6rem;
        max-width: 13ch;
        line-height: 5rem;
    }

    .hero p {
        font-size: 2.5rem;
    }
}