.services--b {
    margin-top: 5rem;
    overflow: hidden;
}

.services--b--container {
    display: grid;
    max-width: 1200px;
    margin: auto;
    overflow: hidden;
}

.services--left {
    min-height: 400px;
    margin: 1rem;
}

.services--left img {
    width: 100%;
    /* height: 100%; */
    min-height: 400px;
    border-bottom-right-radius: 4rem;
    border-top-right-radius: 4rem;
    object-fit: cover;
    object-position: 80% 0;
}

.services--right {
}

.services--right--container {
    padding: 1rem;
}

.services--right ul {
    margin: 1rem 0 0 0;
    font-weight: bold;
    list-style: none;
    width: 100%;
    display: flex;
    gap:.5rem;
    flex-direction: column;
}

.services--right li {
    cursor: pointer;
    transition: all .25s ease;
    padding: 0 1rem 0 1.5rem;
}

.services--right li:hover  {
    translate: .25rem 0;
}

@media (min-width: 1024px) {
    .services--b {
        margin-top: 3rem;
    }
    .services--left img {
        min-height: 500px;
    }
    .services--b--container {
        display: grid;
        grid-template-columns: 1fr 1fr;
        gap: 2rem;
    }
}