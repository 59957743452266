.services--bullet {
    overflow: hidden;
    font-weight: 400;
    max-width: 500px;
    padding: 0 2.5rem 0 1rem ;
    transition: all 1s ease;
}

.service-bullet-item {
    align-items: center;
    width: 100%;
    gap: .5rem;
}

.collapsed {
    max-height: 0;
}

.bullet--arrow {
    width: 20px;
    height: 20px;
    transition: all .25s ease;
    margin-right: .1rem;
}

.rotated90 {
    transform: rotate(90deg);
}