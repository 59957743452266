.services {
    padding-top: 3rem;
}

.services--container {
    max-width: 1000px;
    margin: auto;
    padding: 1rem;
}

.services--underline {
    display: flex;
    width: 200px;
    height: 3px;
    background-color: var(--main);
    margin: 1rem auto 1rem auto;
}

.services--message {
    text-align: center;
}

.services--message p {
    margin-top: 1rem;
    padding: .5rem 1rem 0 1rem;
}

.services--grid {
    margin-top: 1rem;
    display: grid;
    gap: 1rem;
}

.services--item {
    position: relative;
    cursor: pointer;
}

.services--item:hover h4 {
    translate: 0 -.1rem;
}

.services--item:hover img {
    filter: saturate(1) ;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 30px 60px -12px, rgba(0, 0, 0, 0.3) 0px 18px 36px -18px;
}

.services--item img {
    width: 100%;
    border-radius: .25rem;
    transition: all .25s ease;
    filter: saturate(.6);
}

.services--item h4, .services--item p {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
    color: var(--light);
    text-transform: capitalize;
}

.services--item h4 {
    font-size: 2rem;
    text-shadow: 2px 1px 0 #000000e0;
    transition: all .25s ease;
}

.services--item p {
    font-size: 1.3rem;
    width: 100%;
    padding: .1rem;
    text-shadow: 1px 1px 0 #000000e0;
}

@media (min-width: 400px) {
    .services--item p {
        font-size: 1.5rem;
    }
}

@media (min-width: 640px) {
    .services--grid {
        grid-template-columns: 1fr 1fr;
    }
    .services--item p {
        font-size: 1.2rem;
    }

}

@media (min-width: 700px) {
    .services--item p {
        font-size: 1.5rem;
    }
}

@media (min-width: 1024px) {
    .services--grid {
        grid-template-columns: 1fr 1fr 1fr;
    }
    .services--item p {
        font-size: 1.3rem;
    }
}

@media (min-width: 1200px) {
    .services--message p {
        font-size: 1.25rem;
    }
    
}