.why-us {
    padding: 1rem 1rem 4rem 1rem;
    max-width: 1200px;
    margin: auto;
    margin-top: 4rem;
}

.why-us h3 {
    margin-bottom: 1rem;
    width: 9ch;
}

.why-us h4 {
    margin-top: 1rem;
    margin-bottom: .5rem;
}

.why-us--underline {
    display: flex;
    width: 200px;
    height: 3px;
    background-color: var(--main);
    margin: 1rem auto 1rem 0;
}

.why-us--grid {
    display: grid;
}

.why-us--right img {
    width: 100%;
    border-top-left-radius: 4rem;
    border-bottom-left-radius: 4rem;
    border-bottom-right-radius: 4rem;
    max-height: 400px;
    object-fit: cover;
}

.why-us--right p {
    margin-top: .5rem;
}

.why-us--bottom {
    display: grid;
    gap: 1rem;
    margin-top: 4rem;
}

.why-us--bottom h4 {
    font-size: 1.5rem;
}

.why-us--bottom img {
    width: 100%;
    border-radius: 2rem;
}

@media (min-width: 480px) {
    .why-us--bottom {
        grid-template-columns: 1fr 1fr;
    }
}

@media (min-width: 768px) {
    .why-us--right p {
        font-size: 1.25rem;
    }

    .why-us--grid {
        grid-template-columns: 2fr 3fr;
    }

    .why-us--bottom {
        grid-template-columns: 1fr 1fr 1fr;
        gap: 1.5rem;
    }
    
    .why-us--bottom p {
        font-size: 1.15rem;
    }
    
}

@media (min-width: 1200px) {
    .why-us {
        margin-top: 8rem;
    }

    .why-us--bottom {
        
        gap: 2.5rem;
    }

    .why-us--right p {
        font-size: 1.1rem;
        margin-top: 1.5rem;
    }

    .why-us--bottom p {
        font-size: 1.25rem;
    }
}