.signup {
    background-color: #222;
}

.signup--grid {
    display: grid;
    margin-bottom: 6rem;
    min-height: 70vh;
    background-image: url('https://cdn.shopify.com/s/files/1/0738/2987/6008/files/stevetech-it.png?v=1710363070');
    background-position: 80% 20%;
}

.form--wrapper {
    width: 100%;
    max-width: 1200px;
    margin: auto;
}

.signup--form {
    max-width: 580px;
    display: flex;
    gap: .5rem;
    flex-direction: column;
    background-color: var(--light);
    margin: auto;
    padding: 1rem 2rem;
    border-radius: .5rem;
}

.signup--form h2 {
    text-align: center;
}

.signup--form label {
    display: flex;
}

.signup--form img {
    width: 28px;
    height: 28px;
    margin-right: 1rem;
}

.signup--form input {
    width: 100%;
    padding: .25rem;
    border-radius: .25rem;
    border: 1px solid silver;
    font-size: 1.2rem;
}

.signup--form button {
    padding: .25rem;
    background-color: var(--main);
    color: var(--light);
    border-radius: .25rem;
    font-size: 1.2rem;
}

.error-message {
    color: red;
    text-transform: capitalize;
}



@media (min-width: 900px) {
    .signup--grid {
        /* grid-template-columns: 1fr 1fr; */
    }

    .signup--right {
        translate: -10% 0;
    }

}