.contact {
    background-color: #222;
    min-height: 100vh;
    padding-top: 12rem;
    color: var(--light);
}

.contact--grid {
    display: grid;
    padding-bottom: 10rem;
}

.contact--left img {
    width: 100%;
}

.contact--right {
    width: 100%;
    translate: 0 -50% ;
    margin: auto;
    display: grid   ;
    place-content: center;
    max-width: 700px;
}

.heading--container {
    padding: 1rem;
    text-shadow: 1px 1px 2px rgba(20, 0, 0, 1);
}

.heading--container h2 {
    color: var(--light);
    font-size: 2rem;
}

.heading--container p {
    margin-top: .5rem;
    font-size: 1.4rem;
}

.form--container {
    margin-top: 1rem;
    background-color: var(--light);
    padding: 2rem;
    border-radius: 1rem;
}

.form--container form {
    display: flex;
    flex-direction: column;
    gap: 1.2rem;
}

.form--container button {
    padding: .5rem;
    background-color: var(--main);
    color: var(--light);
    border-radius: .25rem;
    font-size: 1.4rem;
}

.form--container textarea {
    min-height: 100px;
}

.form--container input, .form--container textarea {
    border-radius: .25rem;
    border: 1px solid silver;
    padding: .25rem .25rem .25rem 1rem;
    font-size: 1.2rem;
    width: 100%;
    background-color: white;
}



.form--container label {
    display: flex;
}

.form--container img {
    width: 28px;
    height: 28px;
    margin-right: 1rem;
}


.input--container {
    gap: 1rem;
    width: 100%;
}



@media (min-width: 900px) {
    .contact--grid {
        grid-template-columns: 1fr 1fr;
    }

    .contact--right {
        translate: -10% 0;
    }

    .heading--container h2 {
        font-size: 3rem;
    }

    .heading--container p {
        font-size: 1.5rem;
    }
}