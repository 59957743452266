

.careers ul {
    list-style: none;
}   

.careers--bg {
    background-image: url('https://cdn.shopify.com/s/files/1/0738/2987/6008/files/stevetech_5.png?v=1710363713');
    background-position: 70% 10%;
}

.cards {
    display: grid;
    gap: 1rem;
    margin-top: 4rem;
}

.cards p {
    font-size: .9rem;
    margin: .5rem 0 1rem 0;
}

.cards img {
    margin-top: 1.5rem;
}

.card--wrapper {
    border: 2px solid #222;
    height: 100%;
    border-radius: 2rem;
    padding: 0rem 2rem;
    box-shadow: rgba(17, 12, 46, 0.053) 0px 48px 100px 0px;
    background-color: transparent;
}

.card--text {
    font-size: .9rem !important;
}


@media (min-width: 500px) {
    .cards {
        grid-template-columns: repeat(2, 1fr);
    }

}

@media (min-width: 900px) {
    .cards {
        grid-template-columns: repeat(4, 1fr);
    }
   
}