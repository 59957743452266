.apply-job--bg {
    background-image: url('https://cdn.shopify.com/s/files/1/0738/2987/6008/files/stevetech-it.png?v=1710363070');
    background-position: 70% 10%;
}

.job-description p {
    margin: 1.5rem 0;
}

.job-description li {
    margin: 0 1rem;
}

.apply--form {
    margin-top: 4rem;
    display: flex;
    flex-direction: column;
    gap: 1rem;
    max-width: 600px;
}

.apply--form input, .apply--form textarea {
    padding: .25rem;
    font-size: 1.2rem;
}

.apply--form label {
    display: flex;
    flex-direction: column;
    gap: .5rem;
    font-weight: 600;
}

.apply--form button {
    width: fit-content ;
    padding: 1rem 2rem;
}

.applied {
    margin: 2rem 0 1rem 0;
    font-size: 2rem !important;
}

.more-jobs {
    font-size: 2rem;
    font-weight: 600;
    margin-top: 4rem;
    text-decoration: none;
    color: #222;
}