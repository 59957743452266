.page {
    min-height: 100vh;
    background-color: #222;
  }
  
  .page h2   {
    font-size: 3rem;
    max-width: 20ch;
    text-shadow: 2px 7px 5px rgba(0,0,0,0.3), 
      0px -4px 10px rgba(22, 22, 22, 0.3);
  }
  
  .page h3 {
    margin-top: 3rem;
    margin-bottom: 1rem;
    font-size: 3rem;
  }
  
  .page h4 {
    margin-top: .5rem;
    margin-bottom: .5rem;
    font-size: 1.2rem;
  }

  .page p {
    font-size: 1.2rem;
  }
  
  .page--hero {
    display: grid;
    min-height: 70vh;
    place-content: center;
    text-align: center;
    color: var(--light);
    padding: 0 1rem;
  }
  
  .page--hero strong {
    color: var(--main);
  }
  
  .page--content {
    background-color: white;
  }
  
  .content--wrapper {
    max-width: 1200px;
    margin: auto;
    min-height: 1000px;
    padding: 2rem 1rem;
  }
  
  @media (min-width: 1024px) {
    .page h2 {
      font-size: 4rem;
    }
  }
  
  @media (min-width: 1280px) {
   
  }