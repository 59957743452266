.footer-nav {
    background-color: #222;
    padding: 1rem 1rem 6rem 1rem;
    display: grid;
    place-content: center;
}

.footer-nav menu {
    display: flex;
    flex-direction: column;
    text-align: center;
    gap: 1rem;
    list-style: none;
    margin: 3rem 0 0 0;
    justify-content: center;
}

.footer-nav a {
    color: var(--light);
    text-decoration: none;
}

.bottom--logo {
    width: 300px;
    margin: 0 auto 0 auto;
    display: grid;
    place-content: center;
    padding-top: 8rem;
}

.bottom--logo img {
    width: 200px;
    margin: auto;
}

@media (min-width: 768px) {
    .footer-nav menu {
        flex-direction: row;
    }
}